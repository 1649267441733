import React, { useEffect } from "react"
import Search from "../../components/home/search";
import { isEmpty } from 'lodash';
import Layout from "../../components/layout";
// import Carousel from "../../components/home/carousel";
import SEO from "../../components/seo";
import { getOgImage } from "../../utils/functions";
import { sanitize } from "../../utils/functions";

import Link from "gatsby-link";

const ArchivePage = ( props ) => {

	const {
		      pageContext: {
			      categories,
			      category,
			      category: { name, seo, uri },
			      postSearchData: { products, options }
		      }
	      } = props;


				useEffect(() => {
				if(props.pageContext.category.translations[0].link){
				document.getElementById('lang-switch').href= props.pageContext.category.translations[0].link
				}
				});

							let enCats = []

							//console.log(categories)

							categories.nodes.forEach((item, i) => {
								//console.log(item.language.code === "PL")
								if(item.language.code === "EN"){
									enCats.push(item)
								}
							});
							//console.log(plCats)
							let filterCats = {nodes:enCats}


	return (
		<Layout>
			{
				! isEmpty( props.pageContext ) ? (
					<>
						<SEO
							title={ name }
							seoData={ seo }
							uri={ uri }
							header={ { siteTitle: ' Shop' } }
							openGraphImage={ getOgImage( seo ) }
						/>
						<div key={category.id} className="row cat-slajd" style={{display:'flex',flexWrap:'wrap',minWidth:'100%',marginLeft:'0',alignItems:'center',backgroundColor:'#E8D3D0'}}>
							<div className="carousel-info col" style={{minHeight:'250px',textAlign:'center'}}>
								<div className="container">
									<div className="carousal-info-inner" style={{textAlign:'left'}}>
										<h1 className="spec-h">{ category.name }</h1>
										<div className="text-small" dangerouslySetInnerHTML={ { __html: sanitize( category.description ) } }/>
										<br />
										<Link
											to={category.uri+'#archive'}
											tabIndex="-1"
											className="btn btn-outline-dark"
										>Explore...
										</Link>
									</div>
								</div>
							</div>

							<div className="carousel-img-wrap col">
								{!isEmpty(category.image) &&
								!isEmpty(category.image.sourceUrl) ? (
									<img
										className="cat-img"
										src={ category.image.sourceUrl }
										alt={
											!isEmpty(category.image.altText)
												? category.image.altText
												: category.name
										}
									/>
								) : null}
							</div>
						</div>
						<Search
							products={ products }
							initialProducts={ category.products.nodes }
							engine={ options }
							category={ category }
							categories={ filterCats }
						/>
						<p className="mt-5 mb-5" style={{textAlign:'center'}}>
						<Link to="/" className="btn btn-outline-dark mush">Go back</Link>
						</p>
					</>
				) : (
					<div>Something went wrong</div>
				)
			}
		</Layout>
	)
};
export default ArchivePage;
